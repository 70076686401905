:root {
  --color-primary: 253, 63, 108;
  --color-secondary: 58, 87, 187;
  --color-tertiary: 8, 10, 82;
  --color-success: 85, 136, 124;
  --color-warning: 255, 174, 3;
  --color-danger: 236, 35, 42;

  --color-light-blue: 79, 182, 240;
  --color-dark-blue-grey: 27, 39, 81;
  --color-dark-blue-grey-2: 23, 33, 69;
  --color-dark-grey-blue: 51 48 106;
  --color-dark-grey-blue-2: 43, 51, 87;
  --color-dark-grey-blue-3: 19, 27, 58;
  --color-midnight-blue: 8, 10, 52;
  --color-mainBgColor: 255, 255, 255;
  --color-dark-purple: 87, 47, 140;
  --color-lighter-purple: 149, 93, 241;
  --color-dusk: 84, 93, 124;
  --color-dusk-blue: 50, 75, 167;
  --color-cornflower-blue: 70, 103, 219;
  --color-battleship-grey: 107, 108, 133;
  --color-dark-sky-blue: 79, 182, 240;
  --color-blueberry: 87, 47, 140;
  --color-dark-blue: 27, 39, 81;
  --color-ultra-dark-blue: 44, 46, 65;
  --color-light-gray-blue: 238, 238, 241;
  --color-bright-cyan: 70, 207, 255;
  --color-powder-blue: 176, 192, 252;
  --color-dark-blue-grey-3: 15, 22, 47; 
  --color-dark-sea-green: 17, 145, 113;
  --color-pinkish-grey: 209, 209, 209;

  --color-objective-attention: 35, 204, 255;
  --color-objective-validation: 253, 62, 107;
  --color-objective-engagement: 255, 173, 94;
  --color-objective-charisma: 222, 121, 215;
  --color-objective-surprise: 90, 213, 187;
  --color-objective-inspire: 85, 122, 255;
  --color-objective-intensity: 167, 90, 246;
  --color-objective-reject: 255, 134, 110;
  --color-objective-horror: 175, 198, 104;
  --color-objective-upset: 253, 121, 159;
  --color-objective-scared: 135, 140, 233;

}
html {
  scroll-behavior: smooth;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

/** Floating Labels */
.help-text {
  letter-spacing: -0pt;
  line-height: 15pt;
  font-size: 9pt;
  font-weight: 500;
  opacity: 0.6;
}

label, input {
  transition: all 0.2s;
  touch-action: manipulation;
}

input {
  cursor: text;
}

input:placeholder-shown + label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
 .text > ::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.text, .text > * {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

/**
* Show the placeholder when the input is focused.
*/
input:focus::-webkit-input-placeholder {
  opacity: 1;
}

input:not(:placeholder-shown) + label,
input:focus + label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
}
/** -------------- **/

.no-overflow-x, .no-overflow-x > * {
  overflow-x: hidden !important;
}

.Toastify__toast-container {
  min-width: 26em;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-mainBgColor font-montserrat;
  }
  .floating-label input:not(:placeholder-shown) + label,
  input:focus + label,
  .floating-label textarea:not(:placeholder-shown) + label,
  textarea:focus + label {
    @apply transform scale-75 -translate-y-4 z-0 ml-0 px-1 py-0 text-primary;
  }
  /* .floating-label input:focus-within ~ label,
  .floating-label input:not(placeholder-shown) ~ label {
    @apply transform scale-75 -translate-y-4 z-0 ml-0 px-1 py-0 text-primary;
  }
  .floating-label textarea:focus-within ~ label,
  .floating-label textarea:not(:placeholder-shown) ~ label {
    @apply transform scale-75 -translate-y-4 z-0 ml-0 px-1 py-0 text-primary;
  } */
  .help-text {
    @apply font-montserrat text-dark-grey-blue-2;
  }
  .Toastify__toast--success {
    @apply bg-dark-sea-green font-montserrat font-semibold text-lg;
  }
  .Toastify__toast--error {
    @apply bg-danger font-montserrat font-semibold text-lg;
  }
}

.videoCamera {
  position: relative;
  width: 100%;
  height: 100%;
}

.scanline {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #FD3E6B;
  box-shadow: 0px 3px 10px 0px #FE406C; 
  animation: scan 2s linear forwards;
}

@keyframes scan {
  0% {
      top: 0%;
  }
  33% {
      top: 100%;
  }
  66% {
      top: 0%;
  }
  100% {
      top: 100%;
      opacity: 0;
  }
}

.cardCamera {
  width: 624px;
  height: 590px;
  border-radius: 0.5rem;
}

.videoCameraContainer {
  width: 557.94px;
  height: 307px;
}

.firtParagraph {
  width: 557px;
  height:48px;
}

.secondParagraph {
  width: 557px;
  height:36px;
}

@media (max-height: 590px) {
  .cardCamera {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }

  .videoCameraContainer {
    width: 40%;
    height: 65%;
    align-self: center;
    margin-bottom: 5px;
  }

  .firtParagraph {
    width: 75%;
    height: auto;
    align-self: center;
  }
  
  .secondParagraph {
    width: 75%;
    height: auto;
    align-self: center;
    margin-bottom: 5px;
  }
}

@media (max-width: 640px){
  .cardCamera {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }

  .videoCameraContainer {
    width: 100%;
    height: 60%;
  }

  .firtParagraph {
    width: 90%;
    height: auto;
  }
  
  .secondParagraph {
    width: 90%;
    height: auto;
  }
}